import React from "react";

const AboutComponent = () => {

    return (
        <React.Fragment>
            <div className="site-blocks-cover inner-page overlay" style={{ backgroundImage: "url(images/banner-1.jpg)" }} data-aos="fade" data-stellar-background-ratio="0.5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-5 text-center" data-aos="fade">
                        <h1 className="text-uppercase">About Us</h1>
                    </div>
                </div>
            </div>
            {/* <div className="slant-1"></div> */}

            <div className="site-section first-section">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12">
                        <h2 className="site-section-heading text-uppercase font-secondary mb-5">RastaBy</h2>

                            Rastaby is the brand of Karnikara Naithic Ventures Pvt Ltd, with young entrepreneurs experienced in Hotel management, business administration promoted by US based software professional.
                        </div>
                    </div>
                </div>
            </div>

            <div className="site-half first-section container p-0 mt-0">
                <div className="img-bg-1" style={{ backgroundImage: "url('images/plans.jpg')" }} data-aos="fade"></div>
                <div className="px-3">
                    <div className="row no-gutters align-items-stretch">
                        <div className="col-lg-5 ml-lg-auto py-5">
                            <h2 className="site-section-heading text-uppercase font-secondary mb-5">The concept</h2>
                            <p>Traveling is interesting when it is planned. But in many occasions we can not find good hotels while traveling. No one can guide us to a hotel with our taste, convenience and within our budget on the go. Some times we will very strange to that place we will search for good hotels, could not find out and compromised to have food available after a long ride.</p>

                            <p>We will miss good hotels unaware. So introducing an App to locate chain of class hotels, order well in advance, and reserve your seats. No wastage of time and good food for reasonable price.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="site-half block container p-0 mb-5">
                <div className="img-bg-1 right" style={{ backgroundImage: "url('images/food.jpg')" }} data-aos="fade" data-aos-delay="100"></div>
                <div className="px-3">
                    <div className="row no-gutters align-items-stretch">
                        <div className="col-lg-5 mr-lg-auto py-5">
                            <h2 className="site-section-heading text-uppercase font-secondary mb-5">The Vision & mission</h2>
                            <p>To make a brand of chain hotels for travelers anywhere in India. To assure good food at reasonable price and serve without any time lag.</p>

                            <p>As a trustworthy brand of every travelers to make sure the customers get tasty food, hygienic and ambient music restaurants with quality service that will make the traveling comfortable and cost effective including ample parking and other amenities including accommodation.</p>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default AboutComponent;