import React, { useState } from "react";

const ProductComponent = () => {

    const [productList, setProductList] = useState([
        {
            title: 'Masala dosa',
            description: 'Traditional break fast of Keralite. Dosa with Masala preparation, chutney and sambar',
            image: 'images/pdct_01.png'
        },
        {
            title: 'Idly sambar',
            description: 'Internationaly proved best breakfast, Idly Sambar, and chutney',
            image: 'images/pdct_02.jpg'
        },
        {
            title: 'Appam Stew',
            description: 'Non Vegetarian breakfast with mutton stew',
            image: 'images/pdct_03.jpg'
        },
        {
            title: 'Meals with fish curry',
            description: 'Kerala meals with fish curry',
            image: 'images/pdct_04.jpg'
        },
        {
            title: 'Thali meals',
            description: 'Vegetarian meals with variety of curries.',
            image: 'images/pdct_05.png'
        },
        {
            title: 'Biriyani',
            description: 'Chicken biriyani, Mutton biriyani, Vegetable biriyani, Hyderabadi biriyani',
            image: 'images/pdct_06.png'
        },
        {
            title: 'Chappathi & Currry',
            description: 'Chappathi with variety of currries.',
            image: 'images/pdct_07.jpg'
        }
    ]);

    return (
        <React.Fragment>
            <div className="site-blocks-cover inner-page overlay" style={{ backgroundImage: "url(images/banner-1.jpg)" }} data-aos="fade" data-stellar-background-ratio="0.5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-5 text-center" data-aos="fade">
                        <h1 className="text-uppercase">Products</h1>
                    </div>
                </div>
            </div>
            {/* <div className="slant-1"></div> */}

            <div className="site-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span className="caption d-block mb-2 font-secondary font-weight-bold">Food &amp; Services</span>
                            <h2 className="site-section-heading text-uppercase text-center font-secondary">Share after You Taste</h2>
                        </div>
                    </div>
                    <div className="row">

                        {productList.map((item, key) =>
                            <div className="col-md-4 mb-4" key={key}>
                                <div className="media-image" >
                                    <img src={item.image} alt={item.title} className="img-fluid pdct-slider-img" />
                                    <div className="media-image-body">
                                        <h2 className="font-secondary text-uppercase">{item.title}</h2>
                                        <p style={{ minHeight: 82 }}>{item.description}</p>
                                        {/* <p><a href="#" className="btn btn-outline-primary px-4">View More</a></p> */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default ProductComponent;