import React from 'react'; 

import AppRouter from './Router';

function App(props) {
  return (
    <div className="site-wrap">
      <AppRouter/>
    </div>
  );
}

export default App;
