import React from "react";

import AppHeader from '../components/header';
import AppFooter from '../components/footer'

const AppLayout = (props) => {
    return (
        <React.Fragment>
            <AppHeader />
            {props.children}
            <AppFooter />
        </React.Fragment>
    )
}

export default AppLayout