import React, { useState } from "react";
import { Link } from "react-router-dom";

const HomeComponent = () => {

    const [productList, setProductList] = useState([
        {
            title: 'Masala dosa',
            description: 'Traditional break fast of Keralite. Dosa with Masala preparation, chutney and sambar',
            image: 'images/pdct_01.png'
        },
        {
            title: 'Idly sambar',
            description: 'Internationaly proved best breakfast, Idly Sambar, and chutney',
            image: 'images/pdct_02.jpg'
        },
        {
            title: 'Appam Stew',
            description: 'Non Vegetarian breakfast with mutton stew',
            image: 'images/pdct_03.jpg'
        },
        {
            title: 'Meals with fish curry',
            description: 'Kerala meals with fish curry',
            image: 'images/pdct_04.jpg'
        },
        {
            title: 'Thali meals',
            description: 'Vegetarian meals with variety of curries.',
            image: 'images/pdct_05.png'
        },
        {
            title: 'Biriyani',
            description: 'Chicken biriyani, Mutton biriyani, Vegetable biriyani, Hyderabadi biriyani',
            image: 'images/pdct_06.png'
        },
        {
            title: 'Chappathi & Currry',
            description: 'Chappathi with variety of currries.',
            image: 'images/pdct_07.jpg'
        }
    ]);

    return (
        <React.Fragment>
            <div className="slide-one-item home-slider owl-carousel">

                <div className="site-blocks-cover inner-page overlay" style={{ backgroundImage: 'url(images/banner-3.png)' }} data-aos="fade" data-stellar-background-ratio="0.5">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-6 text-center" data-aos="fade">
                                <h1 className="font-secondary  font-weight-bold text-uppercase">Started the first chain of hotels in India</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="site-blocks-cover inner-page overlay" style={{ backgroundImage: 'url(images/banner-2.jpg)' }} data-aos="fade" data-stellar-background-ratio="0.5">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-7 text-center" data-aos="fade">
                                <h1 className="font-secondary font-weight-bold text-uppercase">The measure of chain hotels</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="slant-1"></div> */}

            <div className="site-section first-section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-12 text-center" data-aos="fade">
                            <span className="caption d-block mb-2 font-secondary font-weight-bold">Outstanding Amenities</span>
                            <h2 className="site-section-heading text-uppercase text-center font-secondary">Plan with RastaBy</h2>
                        </div>
                    </div>
                    <div className="row border-responsive">
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right" data-aos="fade-up" data-aos-delay="">
                            <div className="text-center">
                                <img src="images/icon-1.png" className="tile-icon" />
                                <h3 className="text-uppercase h4 mb-3">Restaurants</h3>
                                <p>Air conditioned restaurants with ambient music. Vegetarian and non vegetarian foods for travelers. Delicious food prepared professionally by chef. Good service by qualified.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right" data-aos="fade-up" data-aos-delay="100">
                            <div className="text-center">
                                <img src="images/icon-2.png" className="tile-icon" />
                                <h3 className="text-uppercase h4 mb-3">Convenient Location</h3>
                                <p>Located on main road side at your convenience. And easily traceable with timing. You can order your food well in advance.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 border-right" data-aos="fade-up" data-aos-delay="200">
                            <div className="text-center">
                                <img src="images/icon-3.png" className="tile-icon" />
                                <h3 className="text-uppercase h4 mb-3">Parking space</h3>
                                <p>Ample parking area for easy parking and parking assistance.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0" data-aos="fade-up" data-aos-delay="300">
                            <div className="text-center">
                                <img src="images/icon-4.png" className="tile-icon" />
                                <h3 className="text-uppercase h4 mb-3">Other amenities</h3>
                                <p>Wi-fi connectivity, Kids play area, Feeding area, hygienic toilets.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="site-half container p-0">
                <div className="img-bg-1" style={{ backgroundImage: 'url(images/concept.png)' }} data-aos="fade"></div>
                <div className="">
                    <div className="row no-gutters align-items-stretch">
                        <div className="col-lg-5 ml-lg-auto py-5 px-3">
                            <h2 className="site-section-heading text-uppercase font-secondary mb-5">The concept</h2>
                            <p>Traveling is interesting when it is planned. But in many occasions we can not find good hotels while traveling. No one can guide us to a hotel with our taste, convenience and within our budget on the go. Some times we will very strange to that place we will search for good hotels, could not find out and compromised to have food available after a long ride.
                                We will miss good hotels unaware. So introducing an App to locate chain of class hotels, order well in advance, and reserve your seats. No wastage of time and good food for reasonable price.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="site-half block container p-0">
                <div className="img-bg-1 right" style={{ backgroundImage: 'url(images/vision.png)' }} data-aos="fade"></div>
                <div className="">
                    <div className="row no-gutters align-items-stretch">
                        <div className="col-lg-5 mr-lg-auto py-5 px-3">
                            <h2 className="site-section-heading text-uppercase font-secondary mb-5">The Vision & mission</h2>
                            <p>To make a brand of chain hotels for travelers anywhere in India. To assure good food at reasonable price and serve without any time lag.</p>

                            <p>As a trustworthy brand of every travelers to make sure the customers get tasty food, hygienic and ambient music restaurants with quality service that will make the traveling comfortable and cost effective including ample parking and other amenities including accommodation.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="site-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <span className="caption d-block mb-2 font-secondary font-weight-bold">Food &amp; Services</span>
                            <h2 className="site-section-heading text-uppercase text-center font-secondary">Share after You Taste</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 block-13 nav-direction-white">
                            <div className="nonloop-block-13 owl-carousel">
                                {productList.map((item, key) =>
                                    <div className="media-image" key={key}>
                                        <img src={item.image} alt={item.title} className="img-fluid pdct-slider-img" />
                                        <div className="media-image-body">
                                            <h2 className="font-secondary text-uppercase">{item.title}</h2>
                                            <p style={{ minHeight: 82 }}>{item.description}</p>
                                            <p><Link to="/products" className="btn btn-outline-primary px-4">View More</Link></p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="site-section section-counter pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 px-3">
                            <p className="mb-5"><img src="images/app_splash.png" alt="RastaBy App" className="img-fluid" /></p>
                        </div>
                        <div className="col-lg-5 ml-auto px-3">
                            <h2 className="site-section-heading mb-3 font-secondary text-uppercase"> Try RASTABY</h2>
                            <p className="mb-5">Simplified way to locate chain of class hotels, order well in advance, and reserve your seats. No wastage of time and good food for reasonable price.</p>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="counter">
                                        <span className="caption">Current Downloads</span>
                                        <span className="number" data-number="500">0</span>
                                        <span className="number">+</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5 bg-primary">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-6 text-center mb-3 mb-md-0">
                            <h2 className="text-uppercase text-white mb-4" data-aos="fade-up">Get this App</h2>
                            <p className="font-primary text-white">Download from various platforms</p>
                            <a href="#" className="" data-aos="fade-up" data-aos-delay="100" title="Download from Play Store"><img src="images/btn-googleplay.png" /></a>
                            <a href="#" className="ml-4" data-aos="fade-up" data-aos-delay="100" title="Download from App Store"><img src="images/btn-appstore.png" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HomeComponent;