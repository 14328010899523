import React from "react";
import { Link } from "react-router-dom";

const AppFooter = () => {
    return (
        <React.Fragment>
            <footer className="site-footer bg-dark">
                <div className="container">

                    <div className="row">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <h3 className="footer-heading mb-4 text-white">About Us</h3>
                            <p>Rastaby is the brand of Karnikara Naithic Ventures Pvt Ltd, with young entrepreneurs experienced in Hotel management, business administration promoted by US based software professional.</p>
                            <p><Link to="/about" className="btn btn-outline-primary text-white px-4">Read More</Link></p>
                        </div>
                        <div className="col-md-5 mb-4 mb-md-0 ml-auto">
                            <div className="row mb-4">
                                <div className="col-md-5">
                                    <h3 className="footer-heading mb-4 text-white">Quick Menu</h3>
                                    <ul className="list-unstyled">
                                        <li><a href="/">Home</a></li>
                                        <li><Link to="products">Products</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/contact">Contacts</Link></li>
                                    </ul>
                                </div>
                                <div className="col-md-7">
                                    <h3 className="footer-heading mb-4 text-white">&nbsp;</h3>
                                    <ul className="list-unstyled">
                                        <li><a href="/PrivacyPolicy_KNVPL.html">Privacy Policy</a></li>
                                        <li><a href="/TermsAndConditions_KNVPL.html">Terms & Conditions</a></li>
                                        <li><a href="/CancellationAndRefundPolicy.html">Cancellation & Refund Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-2">

                            <div className="row">
                                <div className="col-md-12"><h3 className="footer-heading mb-4 text-white">Follow Us</h3></div>
                                <div className="col-md-12">
                                    <p>
                                        <a href="#" className="pb-2 pr-2 pl-0"><span className="icon-facebook"></span></a>
                                        <a href="#" className="p-2"><span className="icon-twitter"></span></a>
                                        <a href="#" className="p-2"><span className="icon-instagram"></span></a>
                                        <a href="#" className="p-2"><span className="icon-vimeo"></span></a>

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 text-center">
                        <div className="col-md-12">
                            <p>
                                Copyright &copy; {new Date().getFullYear()}. All rights reserved.
                            </p>


                        </div>

                    </div>
                </div>
            </footer>
        </React.Fragment>
    )

}

export default AppFooter