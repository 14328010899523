import React from "react";

const ContactComponent = () => {

    return (
        <React.Fragment>
            <div className="site-blocks-cover inner-page overlay" style={{ backgroundImage: "url(images/banner-1.jpg)" }} data-aos="fade" data-stellar-background-ratio="0.5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-5 text-center" data-aos="fade">
                        <h1 className="text-uppercase">Contact Us</h1>
                    </div>
                </div>
            </div>
            {/* <div className="slant-1"></div> */}

            <div className="site-section first-section" data-aos="fade">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-8">
                            <form action="#" className="p-5 bg-white">
                                <div className="row form-group">
                                    <div className="col-md-12 mb-3 mb-md-0">
                                        <label className="font-weight-bold" for="fullname">Name</label>
                                        <input type="text" id="fullname" className="form-control" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label className="font-weight-bold" for="email">Email</label>
                                        <input type="email" id="email" className="form-control" placeholder="Email Address" />
                                    </div>
                                </div>


                                <div className="row form-group">
                                    <div className="col-md-12 mb-3 mb-md-0">
                                        <label className="font-weight-bold" for="phone">Phone</label>
                                        <input type="text" id="phone" className="form-control" placeholder="Phone" />
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12 mb-3 mb-md-0">
                                        <label className="font-weight-bold" for="phone">Address</label>
                                        <input type="text" id="address" className="form-control" placeholder="Address" />
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label className="font-weight-bold" for="message">Message</label>
                                        <textarea name="message" id="message" cols="30" rows="5" className="form-control" placeholder="Message"></textarea>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <input type="submit" value="Send Message" className="btn btn-primary text-white px-4 py-2" disabled />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-4">
                            <div className="p-4 mb-3 bg-white">
                                <h3 className="h5 text-black mb-3">Contact Info</h3>
                                <p className="mb-0 font-weight-bold">USA</p>
                                <p className="mb-4">17042, Kenyon Pointe, Apt #E, Tustin, California, CA 92780, USA</p>

                                <p className="mb-0 font-weight-bold">India</p>
                                <p className="mb-4">Aithereyam, Kodamthuruth, Kuthiathode PO, Cherthala, <br/>PIN - 688 533</p>
                                <p></p>

                                <p className="mb-0 font-weight-bold">Phone</p>
                                <p className="mb-4"><a href="#">+91 9567431246</a></p>

                                <p className="mb-0 font-weight-bold">Email Address</p>
                                <p className="mb-0"><a href="mailto:rastabyongo@gmail.com">rastabyongo@gmail.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

export default ContactComponent;