import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";

import AppLayout from "./layouts/AppLayout";
import HomeComponent from "./views/home";
import AboutComponent from "./views/about";
import ProductComponent from "./views/products";
import ContactComponent from "./views/contact";

const AppRouter = () => {
    return (
        <Router>
            <AppLayout>
                <Routes>
                    <Route path="/" element={<HomeComponent />}  />
                    <Route path="about" element={<AboutComponent />}  />
                    <Route path="products" element={<ProductComponent />}  />
                    <Route path="contact" element={<ContactComponent />}  />
                </Routes>
            </AppLayout>


        </Router>
    )
}

export default AppRouter;