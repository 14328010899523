import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const AppHeader = () => {
    const location = useLocation();

    return (
        <React.Fragment>
            <div className="site-mobile-menu">
                <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close mt-3">
                        <span className="icon-close2 js-menu-toggle"></span>
                    </div>
                </div>
                <div className="site-mobile-menu-body"></div>
            </div>

            <div className="site-navbar-wrap js-site-navbar bg-white">
                <div className="container">
                    <div className="site-navbar bg-light">
                        <div className="row align-items-center">
                            <div className="col-2">
                                <a href="/"><img src="/images/logo.png" className="mb-0 site-logo" /></a>
                            </div>
                            <div className="col-10">
                                <nav className="site-navigation text-right" role="navigation">
                                    <div className="container">
                                        <div className="d-inline-block d-lg-none ml-md-0 mr-auto py-3"><a href="#" className="site-menu-toggle js-menu-toggle text-black"><span className="icon-menu h3"></span></a></div>

                                        <ul className="site-menu js-clone-nav d-none d-lg-block">
                                            <li><a href="/" activeclassname='active' className={location?.pathname === '/' ? 'active': ''}>Home</a></li>
                                            <li><NavLink to="/about" activeclassname='active'>About</NavLink></li>
                                            <li><NavLink to="/products" activeclassname='active'>Products</NavLink></li>
                                            <li><NavLink to="/contact" activeclassname='active'>Contact</NavLink></li>
                                            <li><a href="#"><span className="d-inline-block bg-primary text-white btn btn-primary">Try App</span></a></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AppHeader;